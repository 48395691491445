import React, { FC, useEffect, useState } from "react";

import { useRegisterEvents, useSigma, useSetSettings, SigmaContainer } from "react-sigma-v2";


const DragNdrop: React.FC = () => {
    const registerEvents = useRegisterEvents();
    const sigma = useSigma();
    const graph = sigma.getGraph();
    const [draggedNode, setDraggedNode] = useState<string | null>(null);
    const setSettings = useSetSettings();


    useEffect(() => {
      // Register the events
      registerEvents({
        downNode: (e) => {
          setDraggedNode(e.node);
          sigma.getGraph().setNodeAttribute(e.node, "highlighted", true);
        },
        mouseup: (e) => {
          if (draggedNode) {
            setDraggedNode(null);
            sigma.getGraph().removeNodeAttribute(draggedNode, "highlighted");
          }
        },
        mousedown: (e) => {
          // Disable the autoscale at the first down interaction
          if (!sigma.getCustomBBox()) sigma.setCustomBBox(sigma.getBBox());
        },
        mousemove: (e) => {
          if (draggedNode) {
            // Get new position of node
            const pos = sigma.viewportToGraph(e);
            sigma.getGraph().setNodeAttribute(draggedNode, "x", pos.x);
            sigma.getGraph().setNodeAttribute(draggedNode, "y", pos.y);

            // Prevent sigma to move camera:
            e.preventSigmaDefault();
            e.original.preventDefault();
            e.original.stopPropagation();
          }
        },
        touchup: (e) => {
          if (draggedNode) {
            setDraggedNode(null);
            sigma.getGraph().removeNodeAttribute(draggedNode, "highlighted");
          }
        },
        touchdown: (e) => {
          // Disable the autoscale at the first down interaction
          if (!sigma.getCustomBBox()) sigma.setCustomBBox(sigma.getBBox());
        },
        touchmove: (e) => {
          if (draggedNode) {
            // Get new position of node
           

            // Prevent sigma to move camera:
          
            e.original.preventDefault();
            e.original.stopPropagation();
            
          }
        },
        // Sigma Kill:
      kill: () => console.log("kill"),
      
      });
    }, [registerEvents, sigma, draggedNode]);

    return null;
  };


export default DragNdrop;