import React, { FC } from "react";
import { BsInfoCircle } from "react-icons/bs";

import Panel from "./Panel";

const DescriptionPanel: FC = () => {
  return (
    <Panel
      initiallyDeployed
      title={
        <>
          <BsInfoCircle className="text-muted" /> Description
        </>
      }
    >
      <p>
        Ce graphe interactif présente une cartographie relationnelle du réseau Chaâbani: sociétés, associés commerciaux, 
         adresses, comptables et notaires, etc. Sont également restitués les liens
         entre Sallam Chaâbani et certains de ses associés avec les Ech-chaouti, protagonistes
          d'un autre dossier de blanchiment.
          </p>
          <p>
         Les principaux suspects et inculpés sont identifiés en <span style={{color: 'red', fontWeight: 'bold'}} >rouge</span>.
         Les deux panneaux latéraux <i>Secteurs</i> et <i>Catégories</i> permettent de trier les noeuds affichés
          selon les critères correspondants.
      </p>
      <p>
      </p>
      <p>
      <p>
      Les noeuds peuvent être déplacés en cliquant et en glissant. En double-cliquant sur un noeud lié à un suspect
       ou à une société, vous serez-renvoyé vers un dashboard présentant les investissements
        des membres du réseau Chaâbani.
      </p>
      <p>
      Deux layouts sont disponibles: le premier, initialement réalisé sur {" "}
      <a target="_blank" rel="noreferrer" href="https://gephi.org/">Gephi</a>{" "}
        avec un algorithme <i>multilevel</i> puis modifié à la main, 
       a été tavaillé de façon à maintenir une certaine proximité
       entre les individus et les sociétés, et organise-ceux-ci autour de trois clusters clairement délimités et différenciés:
        réseau Chaâbani, réseau Soudani et réseau Ech-chaouti. Il s'agit 
        du rendu par défaut.
        </p>
       Le layout <i>Force Atlas</i> est actionnable via le bouton circulaire. <span style={{fontWeight: 'bold'}} >Il est recommandé, préalablement
       à son activation, de ne pas entreprendre d'actions utilisateur (clics, déplacements de noeuds, etc.). Il est 
       donc conseillé de rafraîchir la page et de l'activer immédiatement ensuite, puis de l'interrompre une fois le résultat
        souhaité obtenu. Autrement, des bugs d'affichage peuvent survenir</span>
       </p>
      <p>
      <span>Une fois <i>Force Atlas</i> activé, il est possible de contrôler les noeuds sans entrave,
       de la même façon que sur le graphe par défaut. Le retour à celui-ci se fait ici également via rafraîchissement de la page.</span>
       </p>
       <p>
       <i>Ce graphe a été réalisé sur la base d'une template developpée par{" "}
        <a target="_blank" rel="noreferrer" href="https://www.ouestware.com/en/">
          OuestWare
        </a>
        , en utilisant{" "}
        <a target="_blank" rel="noreferrer" href="https://reactjs.org/">
          react
        </a>{" "}
        et{" "}
        <a target="_blank" rel="noreferrer" href="https://www.sigmajs.org">
          sigma.js</a>, une bitliothèque de visuation de graphes créée par Sciences-Po médialab et OuestWare.
        
        Le code source est disponible sur{" "}
        <a target="_blank" rel="noreferrer" href="https://github.com/jacomyal/sigma.js/tree/main/demo">
          GitHub
        </a></i>
        .
      </p>
       <p>
      </p>
    </Panel>
  );
};

export default DescriptionPanel;
